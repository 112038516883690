import { useEffect } from 'react';
import type { ComponentType } from 'react';
import { Redirect } from 'react-router-dom';

import { useCustomer } from 'app/legacy-customer/hooks';
import { useLoadCustomers } from 'app/legacy-customer/queries';
import { useHelpBeacon } from 'components/help-beacon';
import { PermissionsProvider } from 'components/permissions';
import WelcomeModal from 'components/WelcomeModal';

import type { RouteComponentProps, RouteProps } from '.';
import { PrivateRoute, Route } from '.';

type CustomerRouteProps = {
  customerId: string;
};

type Props = RouteProps & {
  component: ComponentType<RouteComponentProps & CustomerRouteProps>;
};

const CustomerRoute = (props: Props) => {
  const { component: Component } = props;

  const customer = useCustomer();
  const [customers, { LoadingState }] = useLoadCustomers();

  const { setCustomer: setBeaconCustomer } = useHelpBeacon();

  useEffect(() => {
    if (customer) {
      setBeaconCustomer(customer);
    }
    return () => setBeaconCustomer(null);
  }, [customer, setBeaconCustomer]);

  if (customers && !customers.length) {
    // If customer request failed, let `/` choose where to redirect.
    return <Redirect to="/" />;
  }

  if (typeof customers === 'undefined') {
    return <LoadingState />;
  }

  return (
    <PermissionsProvider>
      <WelcomeModal customer={customer} />
      <Route render={(routerProps) => <Component {...routerProps} customerId={customer.id} />} />
    </PermissionsProvider>
  );
};

const PrivateCustomerRoute = (props: Props) => {
  const { component, render, ...rest } = props;
  return (
    <PrivateRoute
      {...rest}
      render={(routerProps) => <CustomerRoute {...routerProps} {...props} />}
      withSetupSteps
      withVerificationSteps
    />
  );
};

export default PrivateCustomerRoute;
