import type { ReactNode } from 'react';

import type { Locale } from '@margobank/components/common/types';
import { useDevice } from '@margobank/components/device';
import { useIntl } from '@margobank/components/intl';
import { Column, Row } from '@margobank/components/layout';
import Logo from '@margobank/components/Logo';
import { Heading, Subheading } from '@margobank/components/text';

import { useLocale } from 'app/auth/hooks';
import laptopImage from 'assets/images/laptop.jpg';
import boosterAccountImageEn from 'assets/images/login-booster-en.png';
import boosterAccountImageFr from 'assets/images/login-booster-fr.png';
import oneShotCardsImage from 'assets/images/login-one-shot-cards.png';
import termDepositImageEn from 'assets/images/login-term-deposit-en.png';
import termDepositImageFr from 'assets/images/login-term-deposit-fr.png';
import vaultImage from 'assets/images/vault.jpg';
import UserDropdown from 'components/UserDropdown';

import {
  AbsoluteTextContainer,
  BlackButton,
  CenteredContent,
  ColumnLeft,
  ColumnRight,
  CoverImage,
  FullScreenRow,
  HeadingsColumn,
  PaddingWrapper,
  UserDropdownWrapper,
  WhiteButton,
  WhiteHeadingsColumn,
} from './_styled';

type Variant =
  | 'booster-account-feature'
  | 'laptop'
  | 'one-shot-cards-feature'
  | 'term-deposit-feature'
  | 'vault';

type Props = {
  children: ReactNode;
  renderFooter?: () => ReactNode;
  variant: Variant;
  withUserDropdown?: boolean;
};

const getImage = (variant: Variant, locale: Locale) => {
  switch (variant) {
    case 'booster-account-feature':
      return locale === 'en' ? boosterAccountImageEn : boosterAccountImageFr;

    case 'laptop':
      return laptopImage;

    case 'one-shot-cards-feature':
      return oneShotCardsImage;

    case 'term-deposit-feature':
      return locale === 'en' ? termDepositImageEn : termDepositImageFr;

    case 'vault':
    default:
      return vaultImage;
  }
};

const SideImageLayout = ({ children, renderFooter, variant, withUserDropdown }: Props) => {
  const { isScreenAtLeastL } = useDevice();
  const { t } = useIntl();
  const { locale } = useLocale();

  return (
    <FullScreenRow>
      <ColumnLeft>
        <PaddingWrapper>
          <Row alignItems="center" justify-content="space-between" width="100%">
            {/* Center the Logo */}
            <Row flex="1"></Row>
            <Logo variant="dark" />
            <Row flex="1" justifyContent="flex-end">
              {withUserDropdown && !isScreenAtLeastL && <UserDropdown variant="light" />}
            </Row>
          </Row>
          <CenteredContent>{children}</CenteredContent>
          {renderFooter && renderFooter()}
        </PaddingWrapper>
      </ColumnLeft>
      <ColumnRight hideFor={{ max: 'l' }}>
        {withUserDropdown && (
          <UserDropdownWrapper>
            <UserDropdown variant="transparent" />
          </UserDropdownWrapper>
        )}
        <CoverImage image={getImage(variant, locale)} />
        {variant === 'booster-account-feature' ? (
          <AbsoluteTextContainer alignItems="center" spacing="s4" textAlign="center">
            <Column alignItems="center" spacing="s2">
              <HeadingsColumn maxWidth="large" paddingX="s8" spacing="s1">
                <Heading>{t('auth.login.boosterAccountFeature.title')}</Heading>
                <Subheading>{t('auth.login.boosterAccountFeature.description')}</Subheading>
              </HeadingsColumn>
            </Column>
            <BlackButton href={t('auth.login.boosterAccountFeature.buttonUrl')} target="_blank">
              {t('auth.login.boosterAccountFeature.buttonText')}
            </BlackButton>
          </AbsoluteTextContainer>
        ) : variant === 'one-shot-cards-feature' ? (
          <AbsoluteTextContainer alignItems="center" spacing="s4" textAlign="center">
            <HeadingsColumn maxWidth="large" paddingX="s8" spacing="s1">
              <Heading>{t('auth.login.oneShotCardsFeature.title')}</Heading>
              <Subheading>{t('auth.login.oneShotCardsFeature.description')}</Subheading>
            </HeadingsColumn>
            <BlackButton href={t('auth.login.oneShotCardsFeature.buttonUrl')} target="_blank">
              {t('auth.login.oneShotCardsFeature.buttonText')}
            </BlackButton>
          </AbsoluteTextContainer>
        ) : variant === 'term-deposit-feature' ? (
          <AbsoluteTextContainer alignItems="center" spacing="s4" textAlign="center">
            <WhiteHeadingsColumn spacing="s1">
              <Heading>{t('auth.login.termDepositFeature.title')}</Heading>
              <Subheading>{t('auth.login.termDepositFeature.description')}</Subheading>
            </WhiteHeadingsColumn>
            <WhiteButton href={t('auth.login.termDepositFeature.buttonUrl')} target="_blank">
              {t('auth.login.termDepositFeature.buttonText')}
            </WhiteButton>
          </AbsoluteTextContainer>
        ) : null}
      </ColumnRight>
    </FullScreenRow>
  );
};

export default SideImageLayout;
