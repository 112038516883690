import queryString from 'query-string';

import { BALANCE_STATE, OVERDRAFT_STATUS } from '@margobank/components/domain/customer/constants';
import type {
  CurrentBalanceInformationDTO,
  LoanProductLiteDTO,
  OverdraftProductLiteDTO,
  ProductLiteDTO,
} from '@margobank/components/domain/customer/types';
import type { EbicsConnectionDescriptor } from '@margobank/components/domain/ebics';
import { EBICS_CONNECTION_STATUS } from '@margobank/components/domain/ebics';
import type { GrantingFileDTO } from '@margobank/components/domain/grantings';
import type {
  GroupTransferOperationDetailsDTO,
  OperationDetailsDTO,
} from '@margobank/components/domain/operations';
import { OPERATION_TYPE } from '@margobank/components/domain/operations';
import { PRODUCT_TYPE } from '@margobank/components/domain/products';

import { selectUser } from 'app/auth/selectors';
import {
  ACCOUNT_NUMBER_TYPE,
  BALANCE_STATE_DURATION_WARNING_DAYS,
  DEFAULT_OPERATIONS_PAGE_SIZE,
} from 'app/legacy-customer/constants';
import { useSelector } from 'common/store';

import { useCustomer } from './hooks';
import type { AccountNumberDTO, AllowedOperationsQueryParams } from './types';

export const getNextOperationsPage = (loadedResultsCount = 0) => {
  return Math.floor(loadedResultsCount / DEFAULT_OPERATIONS_PAGE_SIZE) + 1;
};

export const getAccountNumbersSorted = (accountNumbers: AccountNumberDTO[] = []) => {
  // Sort by account name and then iban name with the main iban first
  const getSortableLabel = ({ name, targetCurrentAccount, type }: AccountNumberDTO) =>
    `${targetCurrentAccount.name}${type === ACCOUNT_NUMBER_TYPE.SECONDARY_IBAN ? name : ''}`;

  return accountNumbers.sort((a, b) => getSortableLabel(a).localeCompare(getSortableLabel(b)));
};

export const getLoansFromProducts = (products: ProductLiteDTO[]) => {
  return products?.filter((product) => product.type === PRODUCT_TYPE.LOAN) as
    | LoanProductLiteDTO[]
    | undefined;
};

export const getOverdraftAmountFromProducts = (products: ProductLiteDTO[]) => {
  const product = getOverdraftFromProducts(products);
  return product?.amount || 0;
};

export const getOverdraftFromProducts = (products: ProductLiteDTO[]) => {
  const overdraft = products
    ?.filter(
      ({ status, type }) =>
        type === PRODUCT_TYPE.OVERDRAFT && status !== OVERDRAFT_STATUS.TERMINATED,
    )
    .shift();
  return overdraft as OverdraftProductLiteDTO | undefined;
};

export const getOverdraftToSignFromGrantingFiles = (grantingFiles: GrantingFileDTO[]) => {
  return grantingFiles.find(
    ({ products, state }) => state === 'SIGNATURE' && products.includes('OVERDRAFT'),
  );
};

/**
 * We want to show the warning X days before the maxNegativeBalanceDuration.
 */
export const isBalanceStateWarningVisible = (
  currentBalance: CurrentBalanceInformationDTO,
  maxNegativeBalanceDuration: number,
) =>
  currentBalance.state === BALANCE_STATE.NEGATIVE &&
  currentBalance.duration >= maxNegativeBalanceDuration - BALANCE_STATE_DURATION_WARNING_DAYS;

export const isEbicsConnectionConfigurationRequired = ({ status }: EbicsConnectionDescriptor) =>
  status !== EBICS_CONNECTION_STATUS.READY &&
  status !== EBICS_CONNECTION_STATUS.WAITING_BANKER_VALIDATION;

export const searchParamsFromQueryParams = (queryParams: AllowedOperationsQueryParams) => {
  const { operationCurrentAccountId, operationId, operationType, ...searchParams } = queryParams;
  return searchParams;
};

export const isPendingGroupTransfer = (operation: OperationDetailsDTO | undefined) => {
  switch (operation?.operationType) {
    case OPERATION_TYPE.GROUP_INSTANT_TRANSFER:
    case OPERATION_TYPE.GROUP_TRANSFER:
      return (operation as GroupTransferOperationDetailsDTO).operationCounters.initiatedCount > 0;
    default:
      return false;
  }
};

export const hasPendingOCR = (operation: OperationDetailsDTO | undefined) => {
  return operation?.extractedData?.status === 'PENDING';
};

export const usePrefilledBankerCalendarUrl = () => {
  const {
    banker: { calendarUrl },
    companyName,
  } = useCustomer();
  const user = useSelector(selectUser);
  const params = {
    email: user?.individual.email,
    firstName: user?.individual.firstName,
    lastName: user?.individual.lastName,
    company: companyName,
  };

  return calendarUrl ? `${calendarUrl}?${queryString.stringify(params)}` : undefined;
};
