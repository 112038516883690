import { Base64 } from 'js-base64';

import type { Locale, WrappedList } from '@margobank/components/common/types';
import {
  createMutationHook,
  createQueryHook,
  extendQueryHook,
  invalidateQueryHooks,
} from '@margobank/components/query-helpers';

import http, { USERS_SERVICE_PREFIX, X_AUTH_TOKEN } from 'common/http';
import createMutationHookWith2FA from 'components/SecondFactorValidation/createMutationHookWith2FA';

import type {
  AnswerInvitationParamsDTO,
  ApprovalRequestDTO,
  DeviceInformationDTO,
  IdentityProofDTO,
  InvitationDTO,
  RequestPhoneNumberVerificationResponseDTO,
  SessionUserDTO,
  TermsAcceptanceDTO,
  UpdatePasswordParamsDTO,
  UserDTO,
} from './types';

const AUTH_LOAD_APPROVAL_REQUEST = 'AUTH_LOAD_APPROVAL_REQUEST';
const AUTH_LOAD_DEVICES_INFORMATION = 'AUTH_LOAD_DEVICES_INFORMATION';
const AUTH_LOAD_IDENTITY_PROOFS = 'AUTH_LOAD_IDENTITY_PROOFS';
const AUTH_LOAD_INVITATION = 'AUTH_LOAD_INVITATION';
const AUTH_LOAD_TEMPORARY_USER = 'AUTH_LOAD_TEMPORARY_USER';
const AUTH_LOAD_USER = 'AUTH_LOAD_USER';
const AUTH_REQUEST_PHONE_NUMBER_VERIFICATION = 'AUTH_REQUEST_PHONE_NUMBER_VERIFICATION';

type UseLoadApprovalRequestArgs = {
  approvalRequestId: string;
  token?: string;
  userId: string;
};

export const useLoadApprovalRequest = createQueryHook(
  AUTH_LOAD_APPROVAL_REQUEST,
  async ({ approvalRequestId, token, userId }: UseLoadApprovalRequestArgs) => {
    const headers = token ? { [X_AUTH_TOKEN]: token } : {};
    const { data } = await http.get<ApprovalRequestDTO>(
      `${USERS_SERVICE_PREFIX}/users/${userId}/approval_requests/${approvalRequestId}`,
      { headers },
    );
    return data;
  },
);

type UseLoadDevicesInformationArgs = {
  userId: string;
};

export const useLoadDevicesInformation = createQueryHook(
  AUTH_LOAD_DEVICES_INFORMATION,
  async ({ userId }: UseLoadDevicesInformationArgs) => {
    const { data } = await http.get<DeviceInformationDTO[]>(
      `${USERS_SERVICE_PREFIX}/users/${userId}/devices`,
    );
    return data;
  },
);

export const useLoadPairedDeviceInformation = extendQueryHook(useLoadDevicesInformation, {
  select: (devicesInformation) => devicesInformation.find(({ name, paired }) => paired && name),
});

export const useLoadUnpairedDeviceInformation = extendQueryHook(useLoadDevicesInformation, {
  select: (devicesInformation) => devicesInformation.find(({ name, paired }) => !paired && name),
});

type UseLoadIdentityProofs = {
  userId: string;
};

export const useLoadIdentityProofs = createQueryHook(
  AUTH_LOAD_IDENTITY_PROOFS,
  async ({ userId }: UseLoadIdentityProofs) => {
    const { data } = await http.get<WrappedList<IdentityProofDTO>>(
      `${USERS_SERVICE_PREFIX}/users/${userId}/identity_proof_candidates`,
    );
    return data.results;
  },
);

type UseLoadInvitation = {
  token: string;
};

export const useLoadInvitation = createQueryHook(
  AUTH_LOAD_INVITATION,
  async ({ token }: UseLoadInvitation) => {
    const { data } = await http.get<InvitationDTO>(`${USERS_SERVICE_PREFIX}/invitations/${token}`);
    return data;
  },
);

type UseLoadTemporaryUserArgs = {
  token: string;
};

export const useLoadTemporaryUser = createQueryHook(
  AUTH_LOAD_TEMPORARY_USER,
  async ({ token }: UseLoadTemporaryUserArgs) => {
    const { data } = await http.get<SessionUserDTO>(`${USERS_SERVICE_PREFIX}/sessions/me/user`, {
      headers: { [X_AUTH_TOKEN]: token },
    });
    return data;
  },
);

export const useLoadUser = createQueryHook(AUTH_LOAD_USER, async () => {
  const { data } = await http.get<UserDTO>(`${USERS_SERVICE_PREFIX}/users/me`);
  return data;
});

type UseRequestPhoneNumberVerification = {
  invitationToken?: string;
  resetPasswordToken?: string;
};

export const useRequestPhoneNumberVerification = createQueryHook(
  AUTH_REQUEST_PHONE_NUMBER_VERIFICATION,
  async ({ invitationToken, resetPasswordToken }: UseRequestPhoneNumberVerification) => {
    const { data } = await http.post<RequestPhoneNumberVerificationResponseDTO>(
      `${USERS_SERVICE_PREFIX}/verification/request`,
      { invitationToken, resetPasswordToken },
    );
    return data;
  },
  {
    // We use an infinite stale time and cache time to avoid unexpected calls here.
    // Each call sends an SMS to the user.
    cacheTime: Infinity,
    select: ({ obfuscatedPhoneNumber }) => obfuscatedPhoneNumber,
    staleTime: Infinity,
  },
);

/**
 * Mutations
 */

type UseAnswerInvitation = {
  answer: AnswerInvitationParamsDTO;
  invitationToken: string;
};

export const useAnswerInvitation = createMutationHook(
  async ({ answer, invitationToken }: UseAnswerInvitation) => {
    const { data } = await http.post(
      `${USERS_SERVICE_PREFIX}/invitations/${invitationToken}/answer`,
      answer,
    );
    return data;
  },
);

type UseConfirmTerm = {
  params: TermsAcceptanceDTO;
  userId: string;
};

export const useConfirmTerms = createMutationHook(async ({ params, userId }: UseConfirmTerm) => {
  const { data } = await http.put(
    `${USERS_SERVICE_PREFIX}/users/${userId}/terms_acceptance`,
    params,
  );
  return data;
});

type UseDeleteIdentityProof = {
  fileId: string;
  userId: string;
};

export const useDeleteIdentityProof = createMutationHook(
  async ({ fileId, userId }: UseDeleteIdentityProof) => {
    const { data } = await http.delete(
      `${USERS_SERVICE_PREFIX}/users/${userId}/identity_proof_candidates/${fileId}`,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks(useLoadIdentityProofs),
  },
);

type UseRequestEmailUpdatedVerification = {
  userId: string;
};

export const useRequestEmailUpdatedVerification = createMutationHook(
  async ({ userId }: UseRequestEmailUpdatedVerification) => {
    const { data } = await http.post(
      `${USERS_SERVICE_PREFIX}/users/${userId}/email_update/request_code`,
    );
    return data;
  },
);

type UseRequestPasswordReset = {
  email: string;
};

export const useRequestPasswordReset = createMutationHook(
  async ({ email }: UseRequestPasswordReset) => {
    const { data } = await http.post(`${USERS_SERVICE_PREFIX}/request_password_reset`, { email });
    return data;
  },
);

type UseRequestPhoneNumberUpdatedVerification = {
  userId: string;
};

export const useRequestPhoneNumberUpdatedVerification = createMutationHook(
  async ({ userId }: UseRequestPhoneNumberUpdatedVerification) => {
    const { data } = await http.post(
      `${USERS_SERVICE_PREFIX}/users/${userId}/phone_number_update/request_code`,
    );
    return data;
  },
);

type UseUpdateUserLocale = {
  locale: Locale;
};

export const useUpdateUserLocale = createMutationHook(async ({ locale }: UseUpdateUserLocale) => {
  const { data } = await http.put(`${USERS_SERVICE_PREFIX}/users/me/locale`, { locale });
  return data;
});

type UseUploadIdentityProof = {
  file: File;
  userId: string;
};

export const useUploadIdentityProof = createMutationHook(
  async ({ file, userId }: UseUploadIdentityProof) => {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await http.post(
      `${USERS_SERVICE_PREFIX}/users/${userId}/identity_proof_candidates`,
      formData,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks(useLoadIdentityProofs),
  },
);

type UseValidateIdentityProofs = {
  identityProofs: string[];
  userId: string;
};

export const useValidateIdentityProofs = createMutationHook(
  async ({ identityProofs, userId }: UseValidateIdentityProofs) => {
    const { data } = await http.put(`${USERS_SERVICE_PREFIX}/users/${userId}/identity_proofs`, {
      identityProofs,
    });
    return data;
  },
);

type UseUpdatePasswordWith2FA = {
  headers?: Record<string, string>;
  params: UpdatePasswordParamsDTO;
};

export const useUpdatePasswordWith2FA = createMutationHookWith2FA(
  async ({ headers, params }: UseUpdatePasswordWith2FA) => {
    const { newPassword, oldPassword } = params;
    const { data } = await http.put(
      `${USERS_SERVICE_PREFIX}/users/me/password`,
      {
        newPassword: Base64.encode(newPassword),
        oldPassword: Base64.encode(oldPassword),
      },
      { headers },
    );
    return data;
  },
);
