import type { CardDisplayType, CardUsage } from '@margobank/components/domain/cards';
import {
  CARD_DISPLAY_TYPE,
  CARD_SETTLEMENT_TYPE,
  CARD_TYPE,
  CARDS_FILTERS_PARAMS,
} from '@margobank/components/domain/cards';
import type { MandateGroup } from '@margobank/components/domain/mandates';
import { GROUP_OPERATION_PATH_TYPE } from '@margobank/components/domain/operations/OperationsList/constants';
import type { GroupOperationPathType } from '@margobank/components/domain/operations/OperationsList/types';
import type {
  OperationCategory,
  OperationType,
} from '@margobank/components/domain/operations/types';

import type { AccountDetailsSection } from 'app/legacy-customer/Accounts/Account/Details';
import type { CapitalAccountDetailsSection } from 'app/legacy-customer/Accounts/CapitalAccount/Details';

import { CUSTOMER_ROUTE } from '../app/legacy-customer/menu';

export const accountDetailsPath = (
  hash: string,
  accountId: string,
  section?: AccountDetailsSection,
) => {
  return `/${hash}/account/${accountId}/details/${section || ''}`;
};

export const accountHistoryCategoryPath = (
  hash: string,
  accountId: string,
  category: OperationCategory,
) => {
  return `/${hash}/account/${accountId}/history?category=${category}`;
};

export const accountHistoryPath = (hash: string, accountId?: string) => {
  return `/${hash}/account/${accountId ?? 'all'}/history`;
};

export const accountHistoryQueryPath = (hash: string, accountId: string, query: string) => {
  return `${accountHistoryPath(hash, accountId)}?query=${query}`;
};

export const accountIBANDetailsPath = (
  hash: string,
  accountId: string,
  secondaryIBANId: string,
) => {
  return `/${hash}/account/${accountId}/ibans?secondaryIBANId=${secondaryIBANId}`;
};

export const accountIBANsPath = (hash: string, accountId: string) => {
  return `/${hash}/account/${accountId}/ibans`;
};

export const accountPath = (hash: string, accountId: string) => {
  return `/${hash}/account/${accountId}`;
};

export const accountDetailsOverdraftPath = (hash: string, accountId: string) => {
  return `/${hash}/account/${accountId}/details/overdraft`;
};

export const accountUpcomingPath = (hash: string, accountId?: string) => {
  return `/${hash}/account/${accountId ?? 'all'}/upcoming`;
};

export const accountingBankAccountMappingsPath = (hash: string) => {
  return `${accountingPath(hash)}/mappings/bank-accounts`;
};

export const accountingExportsPath = (hash: string) => {
  return `${accountingPath(hash)}/operations/exported`;
};

export const accountingOperationsPath = (hash: string, state: string) => {
  return `${accountingPath(hash)}/operations/${state}`;
};

export const accountingPath = (hash: string) => {
  return `/${hash}/accounting`;
};

export const accountingSupplierMappingPath = (hash: string, id: string) => {
  return `${accountingPath(hash)}/suppliers/list?mappingId=${id}`;
};

export const accountsPath = (hash: string) => {
  return `/${hash}/account`;
};

/**
 * Returns the path to the screen listing **all** cards. The list can optionally be filtered by card display type.
 *
 * ⚠️ Only users **with the adequate permissions** will be able to access this route (ie `CARDS_READ`).
 *
 * @param hash The hash of the customer workspace.
 * @param cardDisplayType The *optional* card display type to filter on.
 * @returns The relative path of the route listing all cards for the customer with the adequate query params.
 */
export const allCardsListPath = (hash: string, cardDisplayType?: CardDisplayType) => {
  switch (cardDisplayType) {
    case CARD_DISPLAY_TYPE.PHYSICAL:
      return `/${hash}/cards/all/regular?${CARDS_FILTERS_PARAMS.TYPE}=${CARD_TYPE.PHYSICAL}`;
    case CARD_DISPLAY_TYPE.VIRTUAL:
      return `/${hash}/cards/all/regular?${CARDS_FILTERS_PARAMS.TYPE}=${CARD_TYPE.VIRTUAL}`;
    case CARD_DISPLAY_TYPE.ONE_SHOT:
      return `/${hash}/cards/all/single-usage`;
    default:
      return `/${hash}/cards/all`;
  }
};

export const allCurrentAccountsPath = (hash: string) => {
  return `/${hash}/account/all`;
};

export const apiApplicationsPath = (hash: string) => {
  return `/${hash}/${CUSTOMER_ROUTE.API}/applications`;
};

export const apiApplicationDetailsPath = (hash: string, applicationId: string) => {
  return `${apiApplicationsPath(hash)}/${applicationId}`;
};

export const apiApplicationWebhookDetailsPath = (
  hash: string,
  applicationId: string,
  webhookId: string,
) => {
  return `${apiApplicationDetailsPath(hash, applicationId)}/webhooks/${webhookId}`;
};

export const beneficiaryDetailsPath = (hash: string, beneficiaryId: string) => {
  return `/${hash}/payment/beneficiaries?beneficiaryId=${beneficiaryId}`;
};

export const boosterAccountPath = (hash: string) => {
  return `/${hash}/account/savings`;
};

export const capitalAccountDetailsPath = (
  hash: string,
  capitalAccountId: string,
  section?: CapitalAccountDetailsSection,
) => {
  return `${capitalAccountPath(hash, capitalAccountId)}/details/${section ?? ''}`;
};

export const capitalAccountPath = (hash: string, accountId: string) => {
  return `/${hash}/account/capital/${accountId}`;
};

export const cardDebitPath = (hash: string, cardId: string, paymentReference: string) => {
  return `/${hash}/cards/${cardId}/debit/${paymentReference}`;
};

export const cardDebitsMonitoringPath = (hash: string) => {
  return `/${hash}/cards/debits`;
};

export const cardOperationPath = (hash: string, cardId: string, cardOperationId: string) => {
  return `${cardsListPath(
    hash,
  )}/operations?cardOperationId=${cardOperationId}&selectedCardId=${cardId}`;
};

export const cardPath = (hash: string, cardId: string) => {
  return `/${hash}/cards/${cardId}`;
};

export const cardRenewalPath = (hash: string, cardId: string) => {
  return `/${hash}/cards/${cardId}/renewal`;
};

export const cardSettingsPath = (hash: string, cardId: string, section?: string) => {
  return `/${hash}/cards/${cardId}/settings/${section || ''}`;
};

export const cardsDeferredListPath = (hash: string, usage: CardUsage) => {
  return `/${hash}/cards/all/${usage === 'UNIQUE' ? 'single-usage' : 'regular'}?settlementType=${
    CARD_SETTLEMENT_TYPE.DEFERRED
  }`;
};

export const cardsListPath = (hash: string) => {
  return `/${hash}/cards`;
};

export const crsFatcaPath = (hash: string) => {
  return `/${hash}/crs-fatca`;
};

export const companySettingsPath = (hash: string) => {
  return `/${hash}/company-settings`;
};

export const companySettingsApiPath = (hash: string) => {
  return `${companySettingsPath(hash)}/api`;
};

export const companySettingsWebhookDetailsPath = (hash: string, webhookId: string) => {
  return `${companySettingsApiPath(hash)}/webhooks/${webhookId}`;
};

export const customerEbicsDetailsPath = (hash: string, ebisConnectionId?: string) => {
  return `${companySettingsPath(hash)}/ebics/${ebisConnectionId || ''}`;
};

export const debtPurchaseDetailsPath = (hash: string, debtPurchaseId: string) => {
  return `/${hash}/financing/details/debt-purchase/${debtPurchaseId}`;
};

export const documentsPath = (hash: string) => {
  return `/${hash}/documents`;
};

export const financingPath = (hash: string) => {
  return `/${hash}/financing`;
};

export const getRouteMatchRegex = (routes: string[]) =>
  routes.map((route) => route + '.*').join('|');

export const groupOperationPath = (
  hash: string,
  operationId: string,
  groupOperationPathType: GroupOperationPathType,
  currentAccountId: string,
) => {
  switch (groupOperationPathType) {
    case GROUP_OPERATION_PATH_TYPE.GROUP_OUTGOING_COLLECTION_PATH:
      return groupOutgoingCollectionPath(hash, operationId, currentAccountId);
    case GROUP_OPERATION_PATH_TYPE.GROUP_TRANSFER_PATH:
      return groupTransferPath(hash, operationId, currentAccountId);
    case GROUP_OPERATION_PATH_TYPE.GROUP_TRANSFER_REQUEST_PATH:
      return groupTransferRequestPath(hash, operationId, currentAccountId);
  }
};

export const groupOutgoingCollectionPath = (
  hash: string,
  operationId: string,
  currentAccountId: string,
) => {
  return `/${hash}/outgoing-collections/${currentAccountId}/collection/${operationId}`;
};

export const groupTransferPath = (hash: string, operationId: string, currentAccountId: string) => {
  return `/${hash}/payment/${currentAccountId}/group-transfer/${operationId}`;
};

export const groupTransferRequestPath = (
  hash: string,
  operationId: string,
  currentAccountId: string,
) => {
  return `/${hash}/payment/${currentAccountId}/group-transfer-request/${operationId}`;
};

export const loanDetailsPath = (hash: string, loanId: string) => {
  return `/${hash}/financing/details/${loanId}`;
};

export const mandateCollectionsPath = (hash: string, mandateId: string) => {
  return `/${hash}/collections/${mandateId}`;
};

export const mandateDetailsPath = (hash: string, mandateGroup: MandateGroup, mandateId: string) => {
  return `/${hash}/collections/${mandateGroup}?mandateId=${mandateId}`;
};

export const operationDetailsPath = (
  hash: string,
  currentAccountId: string,
  operationId: string,
  operationType: OperationType,
) => {
  return `/${hash}/account/${currentAccountId}/history?operationId=${operationId}&operationType=${operationType}`;
};

export const outgoingCollectionPath = (
  hash: string,
  type: 'history' | 'scheduled',
  operationId: string,
  operationType: OperationType,
  currentAccountId: string,
) => {
  return `/${hash}/outgoing-collections/${type}?operationId=${operationId}&operationType=${operationType}&operationCurrentAccountId=${currentAccountId}`;
};

export const outgoingMandateCollectionsPath = (hash: string, mandateId: string) => {
  return `/${hash}/outgoing-collections/mandates/${mandateId}`;
};

/**
 * Returns the path to the screen listing all outgoing-collections mandates in the background,
 * with the panel showing the details of a given mandate being open by default.
 *
 * @param hash The hash of the customer workspace.
 * @param mandateId The ID of the mandate that must be opened automatically.
 * @returns The relative path of the route listing all mandates with one mandate panel opened.
 */
export const outgoingMandateDetailsPath = (hash: string, mandateId: string) => {
  return `/${hash}/outgoing-collections/mandates?mandateId=${mandateId}`;
};

export const recurringTransferPath = (hash: string) => {
  return `/${hash}/payment/recurring`;
};

export const remunerationPath = (hash: string, currentAccountId?: string) => {
  return `/${hash}/account/${currentAccountId || 'all'}/remuneration`;
};

export const secondaryIBANPath = (
  hash: string,
  currentAccountId: string,
  secondaryIBANId: string,
) => {
  return `/${hash}/account/${currentAccountId}/ibans?secondaryIBANId=${secondaryIBANId}`;
};

export const subscriptionDetailsPath = (hash: string) => {
  return `/${hash}/company-settings/subscription`;
};

export const termDepositOperationPath = (hash: string, termDepositId: string) => {
  return `${termDepositSummaryPath(hash, termDepositId)}/operations`;
};

export const termDepositSummaryPath = (hash: string, termDepositId: string) => {
  return `${termDepositsPath(hash)}/${termDepositId}`;
};

export const termDepositsPath = (hash: string) => {
  return `/${hash}/term-deposits`;
};

export const transferRequestDetailsPath = (
  hash: string,
  operationId: string,
  currentAccountId: string,
) => {
  return `/${hash}/transfer-requests/all?transferRequestCurrentAccountId=${currentAccountId}&transferRequestId=${operationId}`;
};

export const usersPath = (hash: string) => {
  return `/${hash}/team/users`;
};

export const userEbicsDetailsPath = (hash: string, ebisConnectionId?: string) => {
  return `${userSettingsPath(hash)}/ebics/${ebisConnectionId || ''}`;
};

export const userDetailsPath = (hash: string, userId = '') => {
  return `${usersPath(hash)}/active/${userId}`;
};

export const userSettingsPath = (hash: string) => {
  return `/${hash}/user-settings`;
};
