import { useCallback, useEffect, useState } from 'react';

import { ButtonBase } from '@margobank/components/Button';
import type { AuthenticatedCustomerDTO } from '@margobank/components/domain/customer/types';
import type { GrantingFileDTO } from '@margobank/components/domain/grantings';
import { UserThumbnail } from '@margobank/components/domain/user';
import { Column } from '@margobank/components/layout';
import Modal from '@margobank/components/Modal';
import { Body } from '@margobank/components/text';
import { Icon } from '@memobank/ui';

import type { UserDTO } from 'app/auth/types';

import { StyledIconRow, StyledUserThumbnail } from './_styled';
import UserMenuContent from './UserMenuContent';

export const TEST_ID_USER_MODAL_TRIGGER = 'user-modal-trigger';

type Props = {
  onLogout?: () => void;
  onlyLogout: boolean;
  user: UserDTO;
} & (
  | { customer?: AuthenticatedCustomerDTO }
  | {
      grantingFile?: GrantingFileDTO;
    }
);

const UserMenuModal = ({ onlyLogout, user, ...rest }: Props) => {
  const [isModalMenuOpen, setIsModalMenuOpen] = useState(false);

  const currentCustomer = 'customer' in rest ? rest.customer : undefined;
  const currentGrantingFile = 'grantingFile' in rest ? rest.grantingFile : undefined;

  const companyName = currentCustomer?.companyName ?? currentGrantingFile?.name;

  const close = useCallback(() => {
    setIsModalMenuOpen(false);
  }, [setIsModalMenuOpen]);

  // Closes the modal when the displayed company changes
  useEffect(() => {
    close();
  }, [close, companyName]);

  return (
    <>
      <StyledIconRow
        alignItems="center"
        as={ButtonBase}
        data-testid={TEST_ID_USER_MODAL_TRIGGER}
        onClick={() => setIsModalMenuOpen(!isModalMenuOpen)}
        paddingY={companyName ? 's0_5' : undefined}
      >
        {companyName ? (
          <Body bold ellipsis>
            {companyName}
          </Body>
        ) : (
          <StyledUserThumbnail alignItems="center" spacing="s1">
            <UserThumbnail size="small" user={user} />
          </StyledUserThumbnail>
        )}
        <Icon icon="medium/chevron_down_small" subdued />
      </StyledIconRow>
      <Modal
        isOpen={isModalMenuOpen}
        noPadding
        onRequestClose={() => setIsModalMenuOpen(false)}
        withCloseButton
      >
        <Column paddingBottom="s1" paddingTop="s6">
          <UserMenuContent onItemClicked={close} onlyLogout={onlyLogout} {...rest} />
        </Column>
      </Modal>
    </>
  );
};

export default UserMenuModal;
