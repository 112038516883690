import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { format as formatDate } from 'date-fns';
import type { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce, useMount, useUnmount } from 'react-use';

import { OPTIONAL_FEATURE } from '@margobank/components/domain/customer/constants';
import type { AuthenticatedCustomerDTO } from '@margobank/components/domain/customer/types';
import type { ProductType } from '@margobank/components/domain/products';
import { BACKEND_PERMISSION } from '@margobank/components/domain/user';
import { useIntl } from '@margobank/components/intl';

import type { UserDTO } from 'app/auth/types';
import { ENVIRONMENT, HELPSCOUT_BEACON_ID } from 'common/env';

import { HELP_BEACON_ARTICLE_FR, HELP_BEACON_LABELS } from './constants';
import type { HelpBeaconArticle } from './types';

type FormPrefillOptions = {
  subject?: string;
  text?: string;
};

type HelpBeaconArticleType = 'modal' | 'sidebar';

type HelpBeaconContextType = {
  close: () => void;
  isOpen: boolean;
  isReady: boolean;
  openMessageBox: (options?: FormPrefillOptions) => void;
  setCustomer: (customer: AuthenticatedCustomerDTO | null) => void;
  setUser: (user: UserDTO | null) => void;
  showHelpArticle: (article: HelpBeaconArticle, articleType?: HelpBeaconArticleType) => void;
  toggle: () => void;
};

const PAGE_VIEW_DEBOUNCED_MS = 250;
export const HelpBeaconContext = createContext<HelpBeaconContextType | null>(null);

type Props = {
  children: ReactNode;
};

const HelpBeaconProvider = ({ children }: Props) => {
  const { formatDisplayName, locale, t } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [customer, setCustomer] = useState<AuthenticatedCustomerDTO | null>(null);
  const [user, setUser] = useState<UserDTO | null>(null);

  const location = useLocation();

  const countProducts = useCallback(
    (productType: ProductType) =>
      customer?.products.filter(({ status, type }) => status === 'ACTIVE' && type === productType)
        .length,
    [customer],
  );

  /**
   * If your app is an SPA and your Beacon has Messages that show up on Specific URLs,
   * or if you use URL-based Docs suggestions, then you’ll need to call this method,
   * followed by the Beacon('suggest') method, whenever your app’s URL changes,
   * which is typically done by hooking up to a method of your app’s router.
   */
  useDebounce(
    () => {
      window.Beacon('event', {
        type: 'page-viewed',
        url: document.location.href,
        title: document.title,
      });
      window.Beacon('suggest');
    },
    PAGE_VIEW_DEBOUNCED_MS,
    [location],
  );

  useMount(() => {
    // Event listeners
    window.Beacon('on', 'ready', () => setIsReady(true));
    window.Beacon('on', 'open', () => setIsOpen(true));
    window.Beacon('on', 'close', () => setIsOpen(false));

    // Start
    window.Beacon('init', HELPSCOUT_BEACON_ID);
    window.Beacon('session-data', {
      env: ENVIRONMENT,
    });
    window.Beacon('config', {
      display: {
        position: 'left',
      },
      hideAvatars: true,
      labels: HELP_BEACON_LABELS.reduce((result, label) => {
        result[label] = t(`common.helpBeacon.config.${label}`);
        return result;
      }, {}),
    });
  });

  useUnmount(() => {
    window.Beacon('off', 'open');
    window.Beacon('off', 'close');
  });

  useEffect(() => {
    if (user) {
      const userParams = {
        email: user.individual?.email,
        locale,
        name: user.individual ? formatDisplayName(user.individual) : undefined,
        signature: user.helpscoutSignature,
        userId: user.id,
      };

      const params = customer
        ? {
            ...userParams,
            bankerName: formatDisplayName(customer.banker.individual),
            companyName: customer.companyName,
            currentAccountsCount: countProducts('CURRENT_ACCOUNT'),
            hasCardsAdminPermission: customer.user.permissions
              .includes(BACKEND_PERMISSION.CARDS_ADMIN)
              .toString(),
            hasCardsFeatureEnabled: customer.features.includes(OPTIONAL_FEATURE.CARDS).toString(),
            hasCollectionsWritePermission: customer.user.permissions
              .includes(BACKEND_PERMISSION.COLLECTIONS_WRITE)
              .toString(),
            hasOutgoingCollectionsWritePermission: customer.user.permissions
              .includes(BACKEND_PERMISSION.OUTGOING_COLLECTIONS_WRITE)
              .toString(),
            hasTransfersWritePermission: customer.user.permissions
              .includes(BACKEND_PERMISSION.TRANSFERS_WRITE)
              .toString(),
            lastAccess: customer.lastAccess
              ? formatDate(new Date(customer.lastAccess), 'yyyy-MM-dd')
              : undefined,
            loansCount: countProducts('LOAN'),
            overdraftsCount: countProducts('OVERDRAFT'),
            role: customer.user.role,
          }
        : userParams;

      // The identify method data is only sent when the user opens the Beacon,
      // Helpscout gave us this hack (open => identify => close) to force the data to be sent.
      window.Beacon('open');
      window.Beacon('identify', params);
      window.Beacon('close');
    } else {
      window.Beacon('logout');
      window.Beacon('close');
    }
  }, [countProducts, customer, formatDisplayName, locale, user]);

  const close = useCallback(() => {
    window.Beacon('close');
  }, []);

  const openMessageBox = useCallback((options?: FormPrefillOptions) => {
    window.Beacon('reset');
    if (options) {
      window.Beacon('prefill', options);
    }
    window.Beacon('open');
    window.Beacon('navigate', '/ask/message/');
  }, []);

  const showHelpArticle = useCallback(
    (article: HelpBeaconArticle, articleType?: HelpBeaconArticleType) => {
      window.Beacon('article', HELP_BEACON_ARTICLE_FR[article], { type: articleType || 'sidebar' });
    },
    [],
  );

  const toggle = useCallback(() => {
    window.Beacon('toggle');
    window.Beacon('reset');
    window.Beacon('suggest');
  }, []);

  const context = useMemo(
    () => ({
      close,
      isOpen,
      isReady,
      openMessageBox,
      setCustomer,
      setUser,
      showHelpArticle,
      toggle,
    }),
    [close, isOpen, isReady, openMessageBox, setCustomer, showHelpArticle, toggle],
  );

  return <HelpBeaconContext.Provider value={context}>{children}</HelpBeaconContext.Provider>;
};

export default HelpBeaconProvider;
